@media (width < 900px) {
  .listdialogsdiv {
    display: none;
  }

  .listdialogsdivOpen {
    display: block;
  }
}

@media (width > 900px) {
  .listdialogsdivOpen {
    display: none;
  }

  .listdialogsdiv {
    display: block !important;
  }

  #dialogContainer {
    display: block !important;
  }
}

.color {
  transition-property: background-color;
  transition-duration: 0.5s;
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Blue */
  border-radius: 50%;
  pointer-events: none;
  animation: spinner 1.5s linear infinite;
}

.loadingscreen-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255);
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
}

.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4);
  /*//background-color: rgba(136, 136, 136, 0.4);*/
  display: grid;
  justify-content: center;
  align-items: center;
  z-index: 100000000;
}

p.less {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  margin-bottom: 0px;
}

.moreAction {
  display: flex;
  flex-direction: row;
  justify-items: center;
  justify-content: start;
  max-width: min-content;
}

.PaperDiv {
  width: 100%;
  height: 100%;
  /*height: calc(100vh - 77px - 70px - 60px - 60px - 60px);*/
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  /*border-left: '1px solid #000';*/
}

.ContainerDiv {
  width: 100%;
  height: calc(99vh - 147px - 130px);
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.MessagesBodyDiv {
  width: calc(100% - 0px);
  margin: 0;
  padding: 5px 0 0 0;
  overflow-y: scroll;
  height: calc(100% - 80px);
}

.SandBox {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  position: relative;
}

.SandBoxRef {
  width: calc(100% - 0px);
  margin: 0;
  padding: 5px 0 0 0;
  overflow-y: scroll;
  height: calc(100% - 80px);
}

.MuiCollapse-wrapper {
  height: 100% !important;
}

.containerImg {
  position: relative;
  width: 100%;
  max-width: 400px;
  border-radius: 50%; /* Закругление углов для создания круглой формы */
  /*overflow: hidden; !* Ограничение содержимого круглой областью *!*/
}

.image {
  display: block;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 200px;
  height: 200px;
  opacity: 0;
  transition: 0.3s ease;
  background-color: rgba(0, 0, 0, 0.4);
  border-radius: 60%;
}

.containerImg:hover .overlay {
  opacity: 1;
}

.icon {
  color: white;
  font-size: 80px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
}

.MuiAutocomplete-popper {
  z-index: 100000 !important;
}

/*.MuiTab-root.Mui-selected {*/
/*    color: theme.palette.common.layout;*/
/*}*/

.dropzone {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px;
  /*border: 2px #637381 dashed;*/

  /*border: 2px #b6b6b6 solid;*/
  border-radius: 10px;
  width: 100%;
  /*background: #F9FAFB;*/
  margin: auto;
}

a {
  text-decoration: none;
  color: inherit;
}
