.body {
    font-family: 'Involve', sans-serif;
    margin: 0;
    padding: 0;
    width: 100%;
    background-color: #F9F9F9;
    color: #2C2546;
}
header {
    background: #2C2546;
    color: white;
    padding: 20px;
    text-align: center;
    font-size: 20px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
}
nav {
    background: transparent;
    padding: 10px;
    text-align: center;
    width: 100%;
}
nav a {
    color: #2C2546;
    text-decoration: none;
    margin: 0 15px;
    font-size: 14px;
}
.container {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;

}
.content {
    padding: 20px;
    display: flex;
    /*flex-wrap: wrap;*/
    flex-direction: row;
    align-items: center;
    justify-content: center;
    background: #FFFFFF;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
}
.main-content {
    flex: 2;
    padding: 20px;
}
.sidebar {
    flex: 1;
    padding: 20px 20px 20px 40px;
    background: #F9F9F9;
    border-left: 3px solid #2C2546;
}
.register-btn {
    display: block;
    text-align: center;
    padding: 15px;
    margin-top: 20px;
    font-size: 18px;
    background: #2C2546;
    color: white;
    font-weight: bold;
    text-decoration: none;
    border-radius: 5px;
}
.register-btn:hover {
    background: #1E1A32;
}
.speakers {
    text-align: center;
    padding: 40px 20px;
    background: #F9F9F9;
    margin-top: 20px;
}
.speaker-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}
.speaker {
    background: #FFFFFF;
    /*border: 1px solid #2C2546;*/
    border-radius: 8px;
    max-width: 200px;
    text-align: center;
    padding: 20px;
}
.speaker2 {
    max-width: 350px;
    text-align: center;
    /*padding: 20px;*/
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    gap: 10px;
    justify-content: flex-start;
}
.speaker2 div{
    text-align: left;

}
.speaker img {
    width: 100%;
    border-radius: 8px;
}
.speaker2 img {
    width: 100%;
    max-width: 100px;
    border-radius: 8px;
}
.speaker h3 {
    margin: 10px 0 5px;
}
.speaker p {
    font-size: 14px;
    color: #555;
}
.speaker2 h4 {
    margin: 10px 0 5px;
}
.speaker2 p {
    font-size: 14px;
    margin: 10px 0 5px;
    color: #555;
}
.schedule {
    margin: 0px auto;
    text-align: center;
    padding: 40px 20px;
    background: #F9F9F9;
    width: 100%;
    max-width: 1200px;
}
.schedule-list {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: start;
    justify-content: center;
    gap: 20px;
}
.topic{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 20px;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);*/
    width: 100%;
}
.topic .info {
    text-align: left;
    font-size: 18px;
}
.info p {
    margin: 0;
}

.container2 {
    max-width: 1200px;
    margin: 30px auto;
    padding: 20px;
}
.content2 {
    padding: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /*background: #FFFFFF;*/
    /*box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);*/
    /*border-radius: 8px;*/
}
.main-content2 {
    flex: 2;
    padding: 20px;
}
.sidebar2 {
    flex: 1;
    padding: 20px 20px 20px 40px;
    background: #F9F9F9;
    border-left: 3px solid #2C2546;
    text-align: left;
}

.feedback {
    background: #F9F9F9;
    padding: 40px 20px;
    text-align: center;
    margin-top: 20px;
}
.feedback h2{
    max-width: 700px;
}
.header_form{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
}
.feedback form {
    /*max-width: 500px;*/
    margin: auto;
    background: #FFFFFF;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.feedback button {
    width: 100%;
    padding: 10px;
    background: #2C2546;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}
.feedback button:hover {
    background: #1E1A32;
}
.contacts {
    background: #FFFFFF;
    padding: 40px 20px;
    text-align: center;
    margin-top: 20px;
    border-radius: 8px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}
.contacts p {
    font-size: 16px;
    margin: 10px 0;
}
footer {
    background: #2C2546;
    color: white;
    text-align: center;
    padding: 15px;
    /*margin-top: 20px;*/
}

@media (min-width: 0px) and (max-width: 600px) {
    header {
        padding: 15px;
        font-size: 16px;
    }
    .container {
        margin: 20px auto;
        padding: 5px;
    }
    .content {
        padding: 10px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main-content {
        padding: 10px;
        margin-bottom: 20px;
    }
    .schedule {
        padding: 15px 5px;
    }
    .schedule-list {
        gap: 10px;
    }
    .topic{
        gap: 10px;
        padding: 15px;
    }
    .topic .info {
        text-align: left;
        font-size: 14px;
    }
    .anonce {
        font-size: 14px;
        margin: 10px 0px;
    }
    .speaker2 img {
        max-width: 80px;
    }
    .speaker2 h4 {
        margin: 5px 0 2px;
    }
    .speaker2 p {
        font-size: 12px;
        margin: 10px 0 5px;
        color: #555;
    }
    .feedback {
        padding: 10px 5px;
        margin-top: 10px;
    }
    .container2 {
        margin: 0px auto;
        padding: 0px;
    }
    .content2 {
        padding: 0px;
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
    .main-content2 {
        padding: 0px;
        margin-bottom: 15px;
    }
    .sidebar2 {
        margin-left: 5px;
    }
}