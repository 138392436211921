.reklama {
  color: #6e6cd9;
  text-transform: uppercase;
  font-size: 50px;
  font-weight: 900;
}
.block_text {
}

@media (min-width: 0px) and (max-width: 400px) {
  .header_text {
    font-size: 35px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw));
    height: auto;
  }
  .book_img {
    width: calc((100vw));
    height: auto;
  }
  .text_lead {
    font-size: 30px;
  }
  .text_about {
    font-size: 25px;
  }
  .dot_text {
    font-size: 16px;
  }

  .black_logo_top {
    width: 220px;
    height: auto;
  }
  .gt_logo_top {
    width: 220px;
    height: auto;
  }
}

@media (min-width: 400px) and (max-width: 600px) {
  .header_text {
    font-size: 45px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 0px));
    height: auto;
  }
  .book_img {
    width: calc((100vw - 0px));
    height: auto;
  }
  .text_lead {
    font-size: 30px;
  }
  .text_about {
    font-size: 25px;
  }
  .dot_text {
    font-size: 16px;
  }

  .black_logo_top {
    width: 220px;
    height: auto;
  }
  .gt_logo_top {
    width: 220px;
    height: auto;
  }
}

@media (min-width: 600px) and (max-width: 900px) {
  .header_text {
    font-size: 75px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 50px));
    height: auto;
  }
  .book_img {
    width: calc((100vw - 300px));
    height: auto;
  }
  .text_lead {
    font-size: 30px;
  }
  .text_about {
    font-size: 25px;
  }
  .dot_text {
    font-size: 20px;
  }
  .black_logo_top {
    width: 250px;
    height: auto;
  }
  .gt_logo_top {
    width: 250px;
    height: auto;
  }
}

@media (min-width: 900px) and (max-width: 1200px) {
  .header_text {
    font-size: 90px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 200px));
    height: auto;
  }
  .book_img {
    width: calc((100vw - 400px));
    height: auto;
  }
  .text_lead {
    font-size: 35px;
  }
  .text_about {
    font-size: 30px;
  }
  .dot_text {
    font-size: 22px;
  }

  .black_logo_top {
    width: 250px;
    height: auto;
  }
  .gt_logo_top {
    width: 250px;
    height: auto;
  }
}
@media (min-width: 1200px) and (max-width: 1500px) {
  .header_text {
    font-size: 80px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 200px) / 2);
    height: auto;
  }
  .book_img {
    width: calc((100vw - 200px) / 2.5);
    height: auto;
  }
  .text_lead {
    font-size: 30px;
  }
  .text_about {
    font-size: 30px;
  }
  .dot_text {
    font-size: 20px;
  }
}
@media (min-width: 1500px) and (max-width: 1800px) {
  .header_text {
    font-size: 100px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 200px) / 2);
    height: auto;
  }
  .book_img {
    width: calc((100vw - 200px) / 2.5);
    height: auto;
  }
  .text_lead {
    font-size: 35px;
  }
  .text_about {
    font-size: 30px;
  }
  .dot_text {
    font-size: 20px;
  }
}
@media (min-width: 1800px) {
  .header_text {
    font-size: 120px;
    font-weight: 700;
    color: #ffffff;
  }
  .img_get {
    width: calc((100vw - 200px) / 2);
    height: auto;
  }
  .book_img {
    width: calc((100vw - 200px) / 2.5);
    height: auto;
  }
  .text_lead {
    font-size: 40px;
  }
  .text_about {
    font-size: 30px;
  }
  .dot_text {
    font-size: 20px;
  }
}
